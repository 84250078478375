.host {
  padding: 40px 30px 42px;
  position: relative;

  h2 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.2rem;
    text-align: center;
    margin-bottom: 28px;
  }
}

.content {
  transition: var(--base-transition);
  padding-bottom: 65px;

  &.contentDisabled {
    opacity: 0.5;
  }

  &:not(.contentDisabled) {
    .bodyItem,
    .checkbox {
      cursor: pointer;
    }
  }
}

.counters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  align-items: center;
  padding: 0 20px;
}

.counter {
  border: 1px solid #818181;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 500;
  }

  span {
    font-weight: 600;
    font-size: 3rem;
    line-height: 3.4rem;
  }
}

.table {
  margin-top: 12px;
  max-height: 500px;
  overflow: auto;

  .tableHeader {
    position: sticky;
    top: 0;
    background-color: #fff;
    height: 44px;
    display: grid;
    grid-template-columns: 1.5fr 1.1fr 22px;
    grid-gap: 10px;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    font-weight: 500;
    font-size: 1.4rem;
    padding: 10px 20px;

    & > div {
      display: flex;
      align-items: center;

      svg {
        margin-left: 5px;
      }
    }
  }

  .body {
    background: #fdfdfd;
  }

  .bodyItem {
    display: grid;
    grid-template-columns: 1.1fr 1.1fr 22px;
    grid-gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #e8e8e8;
    transition: var(--base-transition);

    h3 {
      font-size: 1.4rem;
      font-weight: 500;
      word-break: break-word;
    }

    & > div {
      display: flex;
      align-items: center;
    }

    img {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      margin-right: 10px;
    }

    .mealBadges {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 10px;
      justify-self: center;
    }
  }
}

.mealBadge {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.breakfastMealBadge {
  background-color: #f7cf00;
}

.lunchMealBadge {
  background-color: #091bb8;
}

.afternoonSnackMealBadge {
  background-color: #54a651;
}

.footer {
  padding: 20px 50px 20px;
  box-shadow: 0px -3px 16px rgba(0, 0, 0, 0.06);
  background: #fdfdfd;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  button {
    height: 50px;
    font-weight: 600;
    font-size: 1.6rem;
    border-radius: 12px;
  }
}

.checkboxHeaderCell {
  justify-self: end;
}

.excludeBodyItem {
  background-color: #ffeeee;

  img {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  }
}

.mealBadgesLegendDropdown {
  button {
    &:global(.btn) {
      background: none !important;
      border: none !important;
    }
  }

  :global(.dropdown-menu.dropdown-menu-right::before),
  :global(.dropdown-menu.dropdown-menu-right::after) {
    display: none;
  }

  .mealBadgesLegendHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;

    svg {
      path {
        fill: #818181;
      }
    }
  }

  :global(.dropdown-menu) {
    padding: 15px;
    border: 1px solid #e8e8e8;
    border-radius: 7px;
    box-shadow: none;
    top: -34px !important;
  }

  .mealBadgesLegendItem {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    span {
      margin-right: 10px;
    }
  }

  .mealBadgesLegendItem,
  .mealBadgesLegendHeader {
    font-size: 1.4rem;
    font-weight: 500;
  }
}
