.host {
  position: relative;
}

.topActions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.table {
  margin-top: 20px;

  th {
    height: 48px;
  }
}

.amountNegative {
  color: #b22b2b;
}

.classesLink {
  font-weight: 600;
  text-decoration: underline;
  line-height: 2;
  margin-right: 6px;

  &:not(:last-child)::after {
    content: ',';
  }
}

.addTeacherBtn {
  height: 33px;
  border-radius: 6px;

  button {
    font-size: 1.2rem !important;
  }
}

.actions {
  display: grid;
  grid-gap: 10px;

  button {
    height: 48px;
    font-weight: 600;
    font-size: 1.6rem;
  }
}

.schoolsFilter {
  width: 250px;
  height: 33px;

  :global(.dropdown-content) {
    z-index: 2 !important;
  }

  :global(.dropdown-heading),
  :global(.dropdown-container) {
    height: 33px !important;
  }

  :global(.dropdown-heading-value) {
    font-size: 1rem;
  }

  :global(.dropdown-heading-dropdown-arrow) {
    transform: scale(0.6);
    margin-right: -6px;
  }

  :global(.dropdown-search-clear-icon) {
    transform: scale(0.6);
    margin-right: 0;
  }

  input {
    font-size: 1rem;
  }

  :global(.item-renderer) {
    font-size: 1rem;
  }
}

.leftActions {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  align-items: center;
}

.schoolCell {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
  text-overflow: ellipsis;
  text-align: left;
}
