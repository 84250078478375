.dropZoneContent {
  text-align: center;
  color: #818181;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 400;
  height: 80px;
  transition: var(--base-transition);
  cursor: pointer;
  border: #E8E8E8 dashed 2px;
  border-radius: 4px;

  img {
    margin-bottom: 7px;
  }
}

.dragAccepted {
  border-color: #54a651;
}

.dragRejected {
  border-color: #b22b2b;
}

.cropperModal {
  max-width: 700px;
}

.cropper {
  padding: 50px;
  position: relative;

  :global(.close) {
    position: absolute;
    margin: 0;
    right: -90px;
    top: -40px;
  }

  h2 {
    color: #333333;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 20px;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cropBtn {
  margin: 20px auto 0 auto;
  font-size: 1.6rem;
  line-height: 1.8rem;
  width: 400px;
  height: 48px;
  font-weight: 600;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 !important;
  padding: 10px;
}

.previewContainer {
  position: relative;
}

.clearIcon {
  position: absolute;
  top: 12.5px;
  right: 12.5px;

  path {
    transition: var(--base-transition);
    fill: #E8E8E8;
  }

  &:hover {
    path {
      fill: #B22B2B;
    }
  }
}
